// TAGS:

// Generic tags
const GENERIC_TAGS = ['device_type', 'referer_app', 'reason', 'is_disabled'];

// Iniciativa 1pay4all / Sequencer
const VPP_SEQUENCER_TAGS = [
  'button_type',
  'snackbar_type',
  'main_action_target',
  'main_action_fallback',
  'main_action_empty',
  'response_target',
  'response_fallback',
  'response_empty',
  'response_case',
  'end_case',
  'flow_redirect',
  'preload_ready',
  'intervention_id',
  'loading_time',
];

const QUESTIONS_AI_TAGS = [
  'button_type',
  'button_id',
  'classification',
  'like',
  'end_case',
  'has_target',
  'called_from',
  'has_url',
  'has_deeplink',
  'quantity',
  'snackbar_type',
  'is_post_question_from_webview',
  'origin',
  'source',
];

const ADD_TO_CART_TAGS = ['flow_type'];

// Espacio para tags de otras iniciativas...

// TAGS VALUES:

// Iniciativa 1pay4all / Sequencer
const REASON_TAG_VALUES = {
  NO_MAIN_ACTION_TARGET: 'no_main_action_target',
  CATCH_SEQUENCER: 'catch_sequencer',
  UNKNOWN_CASE: 'unknown_case',
  NO_NAVIGATE_TARGET: 'no_navigate_target',
  NO_WEBVIEW_PRELOADED: 'no_webview_preloaded',
  NO_BOTTOMSHEET_TARGET: 'no_bottomsheet_target',
  NO_LANDING_TARGET: 'no_landing_target',
  INVALID_REDIRECT_URL: 'invalid_redirect_url',
  TIMEOUT_WEBVIEW_MESSAGE: 'timeout_webview_message',
  TIMEOUT_INITIAL_LOAD: 'timeout_initial_load',
};

const QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES = {
  SEND_QUESTION: 'send_question',
  FEEDBACK: 'feedback',
  ASK_AGAIN: 'ask_again',
  ASK_SELLER: 'ask_seller',
  DENOUNCE: 'denounce',
  BUY_NOW: 'buy_now',
};

const QUESTIONS_AI_REASON_TAG_VALUES = {
  EMPTY_TARGET: 'empty_target',
  SUGGESTION_RESPONSE_NOT_LOADED: 'suggestion_response_not_loaded',
  ASK_SELLER_SUCCESS: 'ask_seller_success',
  ASK_SELLER_ERROR: 'ask_seller_error',
  FEEDBACK_ERROR: 'feedback_error',
  SHOW_SNACKBAR: 'show_snackbar',
};

const BUTTON_TYPES_TAG_VALUES = {
  ASK_QUESTIONS_AI: 'ask_questions_ai',
};

const UNKNOWN_VALUE = 'UNKNOWN_VALUE';

// Allowed tags
const ALLOWED_TAGS = [...ADD_TO_CART_TAGS, ...GENERIC_TAGS, ...VPP_SEQUENCER_TAGS, ...QUESTIONS_AI_TAGS]; // agregar spread de otras iniciativas

module.exports = {
  ADD_TO_CART_TAGS,
  ALLOWED_TAGS,
  GENERIC_TAGS,
  VPP_SEQUENCER_TAGS,
  QUESTIONS_AI_TAGS,
  REASON_TAG_VALUES,
  BUTTON_TYPES_TAG_VALUES,
  QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES,
  QUESTIONS_AI_REASON_TAG_VALUES,
  UNKNOWN_VALUE,
};
